import React from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { ModalContext } from '../contexts/index';
import { Send } from './icons';

type Props = {
  show?: boolean;
};

const ContactModal: React.FC<Props> = () => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const { modals, close } = React.useContext(ModalContext);

  const formRef = React.useRef<HTMLFormElement>(null);

  const handleSubmit = e => {
    if (formRef.current?.checkValidity()) {
      formRef.current.submit();
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <Modal
      show={modals.contact}
      onHide={() => close('contact')}
      className="modal fade"
      centered
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="addNewCardModalTitle" className="modal-title">
              Contacto
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => close('contact')}
            ></button>
          </div>
          <div className="modal-body">
            <p className="pb-4 text-secondary">
              Llena la siguiente forma para solicitar la apertura de una línea
              de crédito y comenzar a otorgarle a tus proveedores liquidez por
              medio de un programa de pronto pago
            </p>
            <Form
              action="https://api.staticforms.xyz/submit"
              method="post"
              ref={formRef}
              validated={validated}
            >
              <Row>
                <Col sm={12} className="mb-4">
                  <Form.Group controlId="nameValidation">
                    <Form.Control
                      type="text"
                      placeholder="Nombre completo"
                      name="name"
                      maxLength={50}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      El nombre es requerido
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-4">
                  <Form.Group controlId="nameValidation">
                    <Form.Control
                      type="email"
                      placeholder="Correo empresarial"
                      name="email"
                      maxLength={50}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      El correo es requerido
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-4">
                  <Form.Group controlId="nameValidation">
                    <Form.Control
                      type="text"
                      placeholder="Empresa"
                      name="$company"
                      maxLength={50}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      El nombre de la empresa es requerido
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="mb-4">
                  <Form.Group controlId="nameValidation">
                    <Form.Control
                      type="text"
                      placeholder="Estado/Región"
                      name="$state"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      El estado es requerido
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="mb-4">
                  <Form.Group controlId="nameValidation">
                    <Form.Control
                      type="tel"
                      placeholder="Teléfono de empresa"
                      name="phone"
                      maxLength={10}
                      pattern="[0-9]{10}"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      El teléfono es requerido
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="mb-4">
                  <Form.Select aria-label="Ventas anuales" name="$sales">
                    <option>Ventas anuales</option>
                    <option value="20-100">$20mdp - $100mdp</option>
                    <option value="100-500">$100mdp - $500mdp</option>
                    <option value="500+">Más de $500mdp</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={6} className="mb-4">
                  <Form.Select
                    aria-label="Antigüedad de la empresa"
                    placeholder="Antigüedad de la empresa"
                    name="$antiquity"
                  >
                    <option>Antigüedad de la empresa</option>
                    <option value="-1">Menos de 1 año</option>
                    <option value="1-5">1 - 5 años</option>
                    <option value="5+">Más de 5 años</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={6} className="mb-4">
                  <Form.Select
                    aria-label="Industria"
                    name="$industry"
                    aria-required
                  >
                    <option>Industria</option>
                    <option value="agriculture">Agricultura</option>
                    <option value="commerce">Comercio</option>
                    <option value="construction">Construcción</option>
                    <option value="education">Educación</option>
                    <option value="energy">Energía</option>
                    <option value="maintenance">Mantenimiento</option>
                    <option value="production">Manufactura</option>
                    <option value="health">Salud</option>
                    <option value="transport">Transporte</option>
                    <option value="other">Otro</option>
                  </Form.Select>
                </Col>
                <Col sm={12} md={6} className="mb-4">
                  <Form.Group>
                    <Form.Label>Dispuesto a otorgar garantía</Form.Label>
                    <Form.Check
                      label="Sí"
                      name="$guarantee"
                      type="radio"
                      id="form-radio-yes"
                    />
                    <Form.Check
                      label="No"
                      name="$guarantee"
                      type="radio"
                      id="form-radio-no"
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mb-4">
                  <Form.Control
                    as="textarea"
                    placeholder="Comentarios"
                    rows={3}
                    name="$comments"
                  />
                </Col>
                <Col sm={12}>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <Send />
                    &nbsp; Enviar
                  </Button>
                </Col>
              </Row>
              <Form.Control
                type="hidden"
                value={process.env.GATSBY_STATIC_FORM_ACCESS_KEY}
                name="accessKey"
              />
              <Form.Control
                type="hidden"
                value="https://factoro.mx/success"
                name="redirectTo"
              />
              <Form.Control type="text" name="honeypot" className="d-none" />
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
