import React from 'react';
import { ModalProvider } from './src/contexts';
import { DarkModeProvider } from './src/contexts/darkMode';
import { IntercomProvider } from 'react-use-intercom';

const Wrapper = ({ element }) => (
  <IntercomProvider
    appId={process.env.GATSBY_INTERCOM_APP_ID}
    initializeDelay={500}
    autoBoot
  >
    <DarkModeProvider>
      <ModalProvider>{element}</ModalProvider>
    </DarkModeProvider>
  </IntercomProvider>
);

export default Wrapper;
