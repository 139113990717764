exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brokers-tsx": () => import("./../../../src/pages/brokers.tsx" /* webpackChunkName: "component---src-pages-brokers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-facturacion-tsx": () => import("./../../../src/pages/facturacion.tsx" /* webpackChunkName: "component---src-pages-facturacion-tsx" */),
  "component---src-pages-geo-block-tsx": () => import("./../../../src/pages/geo_block.tsx" /* webpackChunkName: "component---src-pages-geo-block-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inversionistas-tsx": () => import("./../../../src/pages/inversionistas.tsx" /* webpackChunkName: "component---src-pages-inversionistas-tsx" */),
  "component---src-pages-mantenimiento-tsx": () => import("./../../../src/pages/mantenimiento.tsx" /* webpackChunkName: "component---src-pages-mantenimiento-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */),
  "component---src-pages-proveedores-tsx": () => import("./../../../src/pages/proveedores.tsx" /* webpackChunkName: "component---src-pages-proveedores-tsx" */),
  "component---src-pages-recursos-calculadora-aguinaldo-tsx": () => import("./../../../src/pages/recursos/calculadora-aguinaldo.tsx" /* webpackChunkName: "component---src-pages-recursos-calculadora-aguinaldo-tsx" */),
  "component---src-pages-recursos-calculadora-isr-tsx": () => import("./../../../src/pages/recursos/calculadora-isr.tsx" /* webpackChunkName: "component---src-pages-recursos-calculadora-isr-tsx" */),
  "component---src-pages-recursos-calculadora-salario-bruto-neto-tsx": () => import("./../../../src/pages/recursos/calculadora-salario-bruto-neto.tsx" /* webpackChunkName: "component---src-pages-recursos-calculadora-salario-bruto-neto-tsx" */),
  "component---src-pages-recursos-ejemplos-cfdi-4-0-tsx": () => import("./../../../src/pages/recursos/ejemplos/cfdi-4-0.tsx" /* webpackChunkName: "component---src-pages-recursos-ejemplos-cfdi-4-0-tsx" */),
  "component---src-pages-recursos-ejemplos-pagos-2-0-tsx": () => import("./../../../src/pages/recursos/ejemplos/pagos-2-0.tsx" /* webpackChunkName: "component---src-pages-recursos-ejemplos-pagos-2-0-tsx" */),
  "component---src-pages-recursos-generador-curp-tsx": () => import("./../../../src/pages/recursos/generador-curp.tsx" /* webpackChunkName: "component---src-pages-recursos-generador-curp-tsx" */),
  "component---src-pages-recursos-generador-de-clabe-tsx": () => import("./../../../src/pages/recursos/generador-de-clabe.tsx" /* webpackChunkName: "component---src-pages-recursos-generador-de-clabe-tsx" */),
  "component---src-pages-recursos-generador-rfc-tsx": () => import("./../../../src/pages/recursos/generador-rfc.tsx" /* webpackChunkName: "component---src-pages-recursos-generador-rfc-tsx" */),
  "component---src-pages-recursos-monedas-usd-mxn-tsx": () => import("./../../../src/pages/recursos/monedas/usd/mxn.tsx" /* webpackChunkName: "component---src-pages-recursos-monedas-usd-mxn-tsx" */),
  "component---src-pages-recursos-simulador-de-credito-tsx": () => import("./../../../src/pages/recursos/simulador-de-credito.tsx" /* webpackChunkName: "component---src-pages-recursos-simulador-de-credito-tsx" */),
  "component---src-pages-recursos-tiie-tsx": () => import("./../../../src/pages/recursos/tiie.tsx" /* webpackChunkName: "component---src-pages-recursos-tiie-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-tyc-tsx": () => import("./../../../src/pages/tyc.tsx" /* webpackChunkName: "component---src-pages-tyc-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */)
}

