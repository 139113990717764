import React from 'react';

import ContactModal from '../components/contact-modal';

type Modals = {
  hubspot?: boolean;
  search?: boolean;
  contact?: boolean;
};

type ModalsContext = {
  modals: Modals;
  open: (modal: string) => void;
  close: (modal: string) => void;
};

type Provider = {
  children?: React.ReactNode;
};

export const ModalContext = React.createContext<ModalsContext>({
  modals: {},
  open: () => {},
  close: () => {},
});

export const ModalProvider: React.FC<Provider> = ({ children }) => {
  const [modals, setModals] = React.useState<Modals>({ hubspot: false });

  const open = (modal: string) => setModals({ [modal]: true });

  const close = (modal: string) => setModals({ [modal]: false });

  return (
    <ModalContext.Provider value={{ modals, open, close }}>
      {children}
      <ContactModal />
    </ModalContext.Provider>
  );
};
