/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import wrapper from './wrapper';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/inter';
import '@fontsource/inter/600.css';
import '@fontsource/urbanist';
import '@fontsource/urbanist/600.css';

export const wrapRootElement = wrapper;
