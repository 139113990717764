import React, { useEffect, useState } from 'react';

type DarkMode = {
  enabled?: boolean;
};

type DarkModeContext = {
  darkMode: DarkMode;
  toggle: () => void;
};

type Provider = {
  children?: React.ReactNode;
};

export const DarkModeContext = React.createContext<DarkModeContext>({
  darkMode: { enabled: false },
  toggle: () => {},
});

export const DarkModeProvider: React.FC<Provider> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<DarkMode>({ enabled: false });
  const [darkReader, setDarkReader] = useState<typeof DarkReader>();

  const toggle = () => setDarkMode({ enabled: !darkMode.enabled });

  useEffect(() => {
    import('darkreader').then(library =>
      setDarkReader(library as typeof DarkReader)
    );
  }, []);

  useEffect(() => {
    if (darkReader) {
      if (darkMode.enabled) {
        darkReader.enable({});
      } else {
        darkReader.disable();
      }
    }
  }, [darkMode.enabled]);

  return (
    <DarkModeContext.Provider value={{ darkMode, toggle }}>
      {children}
    </DarkModeContext.Provider>
  );
};
