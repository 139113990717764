import React from 'react';

const DuotoneIcon = ({ icon = '', alt = '' }) => {
  const [iconData, setIconData] = React.useState();

  React.useEffect(() => {
    import(
      `../../images/duotone-icons/${icon.split(/\d/)[0]}/${icon}.svg`
    ).then(setIconData);
  }, [icon]);

  return (
    <img
      className="avatar-img integration-avatar"
      src={iconData?.default}
      alt={alt}
    />
  );
};

export default DuotoneIcon;
